import { animated } from '@react-spring/web'

import styles from './HeadingGroup.css'

export function HeadingGroup({ title, subtitle = undefined, h = undefined, animation = undefined, layoutClassName = undefined }) {
  return (
    <HeadingGroupBase className={layoutClassName} {...{ animation }}>
      <HeadingGroupTitle {...{ title, h }} />
      {subtitle && <Subtitle layoutClassName={styles.subtitleLayout} {...{ subtitle }} />}
    </HeadingGroupBase>
  )
}

export function HeadingGroupSnackables({ title, subtitle = undefined, h = undefined, animation = undefined, layoutClassName = undefined }) {
  return (
    <HeadingGroupBase className={layoutClassName} {...{ animation }}>
      <TitleSnackables {...{ title }} />
      {subtitle && <SubtitleSnackables layoutClassName={styles.subtitleLayout} {...{ subtitle }} />}
    </HeadingGroupBase>
  )
}

function HeadingGroupBase({ children, animation, className }) {
  return (
    <animated.header className={cx(styles.componentBase, className)} style={animation}>
      {children}
    </animated.header>
  )
}

export function HeadingGroupTitle({ title, h = undefined, layoutClassName = undefined }) {
  /** @type {any} */
  const HBase = h ? `h${h}` : 'h2'

  return <HBase className={cx(styles.componentTitle, layoutClassName)}>{title}</HBase>
}

function TitleSnackables({ title, layoutClassName = undefined }) {
  return <h2 className={cx(styles.componentTitleSnackables, layoutClassName)}>{title}</h2>
}

function Subtitle({ subtitle, layoutClassName }) {
  return <span className={cx(styles.componentSubtitle, layoutClassName)}>{subtitle}</span>
}

function SubtitleSnackables({ subtitle, layoutClassName }) {
  return <span className={cx(styles.componentSubtitleSnackables, layoutClassName)}>{subtitle}</span>
}
