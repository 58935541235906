import { animated } from '@react-spring/web'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Notification.css'

export function Notification({ layoutClassName, icon, notification, iconColorClassName, animation = undefined }) {
  return (
    <animated.div
      className={cx(styles.component, layoutClassName)}
      style={animation}
    >
      <div className={cx(styles.icon, styles.iconLayout)}>
        <Icon {...{ icon }} colorClassName={iconColorClassName} />
      </div>
      <p className={cx(styles.content, styles.contentLayout)}>{notification}</p>
    </animated.div>
  )
}
