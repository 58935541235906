import styles from './Heading.css'

export function HeadingXs({ h, children, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXs, layoutClassName)} {...{ children, h }} />
}

export function HeadingSm({ h, children, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentSm, layoutClassName)} {...{ children, h }} />
}

/** @param {{ h: number, children: any, className: string }} props */
function HeadingBase({ h, children, className }) {
  /** @type {any} */
  const HBase = `h${h}`

  return (
    <HBase className={cx(styles.componentBase, className)}>{children}</HBase>
  )
}
