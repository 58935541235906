import styles from './Input.css'

export function InputText({ value, placeholder, onChange, maxLength, hasIcon = false, layoutClassName = undefined }) {
  return <InputBase type='text' {...{ value, placeholder, onChange, maxLength, hasIcon, layoutClassName }} />
}

export function InputNumber({ value, placeholder, onChange, hasIcon = false, layoutClassName = undefined }) {
  return <InputBase type='number' pattern="\d*" {...{ value, placeholder, onChange, hasIcon, layoutClassName }} />
}

function InputBase({
  type,
  value,
  placeholder,
  onChange,
  hasIcon,
  pattern = undefined,
  maxLength = undefined,
  layoutClassName = undefined
}) {
  const hasValue = Boolean(value.length)

  return (
    <input
      className={cx(
        styles.componentBase,
        hasValue && styles.hasValue,
        hasIcon && styles.hasIcon,
        layoutClassName
      )}
      {...{ type, pattern, value, placeholder, onChange, maxLength }}
    />
  )
}
