import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { Icon } from '/features/buildingBlocks/Icon'

import mediaStyles from '/cssGlobal/media.css'
import styles from './NudgingChevron.css'

import chevronUp from '/images/icons/chevron-up.raw.svg'
import chevronDown from '/images/icons/chevron-down.raw.svg'

export function NudgingChevronCard({ isActive }) {
  return <NudgingChevronBase controllable distance={1.5} {...{ isActive }} />
}

export function NudgingChevronPage() {
  return <NudgingChevronBase distance={1} />
}

function NudgingChevronBase({ distance, isActive = undefined, controllable = false }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const dinstanceWithDirection = isViewportMd ? distance : -1 * distance

  return (
    <div
      style={{ '--distance': dinstanceWithDirection }}
      className={cx(
        styles.componentBase,
        (controllable && !isActive) && styles.paused
      )}
    >
      <Icon icon={isViewportMd ? chevronDown : chevronUp} layoutClassName={styles.iconLayout} />
    </div>
  )
}
