import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'

import { ContainerLg } from '/features/buildingBlocks/Container'

import styles from './Footer.css'

export function FooterHome({ layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentHome, layoutClassName)}>
      <ContainerLg>
        <FooterBase />
      </ContainerLg>
    </div>
  )
}

export function FooterToc() {
  return (
    <div className={styles.componentToc}>
      <FooterBase />
    </div>
  )
}

function FooterBase() {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <div className={styles.componentBase}>
      <p className={styles.poweredByText}>{__`a-publication-of`}<span className={styles.deckIndexPoweredByCursive}>{__`rabobank`}</span></p>

      <p className={styles.aboutRaboText}>
        <strong>{__`rabo-enco`}</strong> {__`rabo-enco-publication-intro-1`} <a href='https://www.rabobank.nl' data-x='link-to-external'>{__`rabobank`}</a> {__`rabo-enco-publication-intro-2`}
      </p>

      <ul className={styles.linksContainer}>
        <Link
          href={__`link-disclaimer`}
          label={__`disclaimer`}
          dataX='link-to-disclaimer'
          layoutClassName={styles.linkLayout}
        />
        <Link
          href={routeMap.app.cookiePolicy({ language })}
          label={__`privacy-and-cookies`}
          dataX='link-to-privacy-and-cookies'
          layoutClassName={styles.linkLayout}
        />
        <Link
          href={routeMap.app.redactie({ language })}
          label={__`editor`}
          dataX='link-to-redactie'
          layoutClassName={styles.linkLayout}
        />
      </ul>
    </div>
  )
}

function Link({ href, label, dataX, layoutClassName = undefined }) {
  return (
    <li className={cx(styles.componentLink, layoutClassName)}>
      <a data-x={dataX} {...{ href }}>{label}</a>
    </li>
  )
}
