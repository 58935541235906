import { useKeenSlider } from 'keen-slider/react'

import { useTranslate } from '/machinery/I18n'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { stopPropagationEventHandlers } from '/machinery/stopPropagationEventHandlers'

import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'

import 'keen-slider/keen-slider.min.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './IssueSlider.css'

import chevron from '/images/icons/chevron-right.raw.svg'
import logo from '/images/branding/rabo-en-co-logo.svg'

export function IssueSlider({ issues, slidesPerView, initialSlide, origin = undefined, title = undefined, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  const { sliderRef } = useKeenSliderRefs({ perView: slidesPerView, initialSlide, origin })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {title && <HeadingSm h={2}>{title}</HeadingSm>}

      <div {...stopPropagationEventHandlers()} ref={sliderRef} className={cx(styles.slider, 'keen-slider')}>
        {issues.map(({ title, image, href }, i) => <IssueCard key={i} {...{ title, image, href }} />)}
        <ArchiefIssueCard href='https://raboenco.pdf.altavia-unite.nl' title={__`archive`} />
      </div>
    </div>
  )
}

function IssueCard({ title, image, href }) {
  return (
    <IssueCardBase dataX='link-to-edition' {...{ title, href }}>
      <ImageCover aspectRatio={1 / 1} {...{ image }} />
      <img src={logo} alt="logo" width='72px' className={styles.logo} />
    </IssueCardBase>
  )
}

function ArchiefIssueCard({ title, href }) {
  return (
    <IssueCardBase dataX='link-to-archive' {...{ title, href }}>
      <img src='/images/archief.png' alt='collection' />
    </IssueCardBase>
  )
}

function IssueCardBase({ title, href, dataX, children, className = undefined }) {
  return (
    <div className={cx(styles.componentIssueCardBase, 'keen-slider__slide', className)}>
      <div className={styles.imageAndLogo}>
        {children}
      </div>

      <ButtonLinkPrimary
        icon={chevron}
        label={title}
        layoutClassName={styles.buttonLayout}
        {...{ href, dataX }}
      />
    </div>
  )
}

function useKeenSliderRefs({ perView, initialSlide, origin }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: initialSlide,
      mode: 'free-snap',
      range: {
        align: true,
      },
      slides: {
        perView,
        origin: origin ?? 0,
        spacing: isViewportMd ? 32 : 16
      },
      slideChanged() { trackDragInteraction('slide_changed') },
      dragStarted() { trackDragInteraction('drag_started') },
      dragEnded() { trackDragInteraction('drag_ended') }
    }
  )

  React.useEffect(
    () => {
      instanceRef.current.update()
    },
    [instanceRef]
  )

  function trackDragInteraction(action) {
    trackInteraction({
      action,
      title: 'interaction',
      type: 'drag'
    })
  }

  return { sliderRef }
}
